.div-input-wrapper{
  display: flex;
}
.otp-no-message{
  text-align: center;
  margin-top: 15px;
  span{
    i{
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
.login-fail-wrapper{
  padding: 20px;
  background: #e6b8b8;
  h4{
    margin-bottom: 0;
  }
}

.login-page {
  height: 100vh !important; 
}